import { createSlice } from '@reduxjs/toolkit';

const userDataSlice = createSlice({
  name: 'userData',
  initialState: {
    data: [],
    validationName: null,
    academies: []
  },
  reducers: {
    info(state, action) {
      state.data = action.payload.userInfo;
    },
    validation(state, action) {
      state.validationName = action.payload.validationName;
    },
    academies(state, action) {
      state.academies = action.payload.academies;
    }
  }
});

export const userDataActions = userDataSlice.actions;

export default userDataSlice;
