import { useState } from 'react';
import { useSelector } from 'react-redux';

import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import DoneIcon from '@mui/icons-material/Done';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import draftToHtml from 'draftjs-to-html';
import { convertToRaw } from 'draft-js';

import UploadFile from 'uI/uploadFile/UploadFile';
import Loading from 'uI/loading/Loading';
import Message from 'uI/message/Message';

import { Topics } from 'constants/Topics';

import { createTicket } from 'store/ticket.actions';

import classes from './NewTicket.module.css';

const NewTicket = () => {
  const [topic, setTopic] = useState(0);
  const [iDTicket, setIDTicket] = useState(0);
  const [openMessage, setOpenMessage] = useState(false);
  const [load, setLoad] = useState(false);
  const [files, setFiles] = useState([]);
  const [editorState, setEditorState] = useState('');
  const [ticket, setTicket] = useState('');
  const [email, setEmail] = useState('');
  const [userName, setUserName] = useState('');
  const [subject, setSubject] = useState('');

  const dataUser = useSelector(state => state.userInfo);

  const onEditorStateChange = editorState => {
    setTicket(
      draftToHtml(convertToRaw(editorState.getCurrentContent()))
    );
    setEditorState(editorState);
  };

  const handleChange = type => event => {
    if (type === 'email') {
      setEmail(event.target.value);
    } else if (type === 'userName') {
      setUserName(event.target.value);
    } else {
      setSubject(event.target.value);
    }
  };

  const createTicketHandler = () => {
    setLoad(true);
    (async () => {
      setIDTicket(
        await createTicket(
          subject,
          ticket,
          files,
          topic,
          userName,
          email
        )
      );
      setLoad(false);
      setOpenMessage(true);
    })();
  };

  const archiveHandler = (file, type) => {
    const newData = {};
    newData[type] = file;
    const newFiles = files;
    newFiles.push(newData);
    setFiles(newFiles);
  };

  const handleCloseMessage = () => {
    setOpenMessage(false);
    setFiles([]);
    setEditorState('');
    setTicket('');
    setEmail('');
    setUserName('');
    setSubject('');
    setTopic(0);
  };

  const changeInputHanlder = event => {
    setTopic(event.target.value);
  };

  return (
    <div className={classes.newTicketContainer}>
      <div className={classes.titleSection}>
        <p className={classes.titleText}>Abrir un nuevo Ticket</p>
        <p className={classes.subTitle}>
          Por favor, complete el siguiente formulario para crear un
          nuevo ticket.
        </p>
      </div>
      <div className={classes.ticketSection}>
        {!dataUser.data.usuario && (
          <aside className={classes.userInfo}>
            <p className={classes.infoTitle}>
              Información de contacto
            </p>
            <p>Direccción del Correo *</p>
            <TextField
              value={email}
              className={classes.input}
              size="small"
              onChange={handleChange('email')}
            />
            <p>Nombre Completo *</p>
            <TextField
              value={userName}
              className={classes.input}
              size="small"
              onChange={handleChange('userName')}
            />
          </aside>
        )}
        <aside
          className={
            !dataUser.data.usuario
              ? classes.createTicket
              : classes.createTicketComplete
          }
        >
          <p className={classes.infoTitle}>Detalles del Ticket</p>
          <em className={classes.infoText}>
            Por favor describe clara y detalladamente tu incidencia.
          </em>
          <p>Titulo de la incidencia *</p>
          <TextField
            value={subject}
            className={classes.input}
            size="small"
            onChange={handleChange('subject')}
          />
          <TextField
            required
            select
            variant="outlined"
            label="Selecciona un tema"
            className={classes.inputSelect}
            value={topic}
            onChange={changeInputHanlder}
            size="small"
          >
            <MenuItem selected disabled value="0">
              Selecciona un tema
            </MenuItem>
            {Topics.map(topic => (
              <MenuItem key={topic.value} value={topic.value}>
                {topic.text}
              </MenuItem>
            ))}
          </TextField>
          <p>
            <strong>
              Descripción clara y detallada de la situación:
            </strong>
          </p>
          <aside className={classes.editorContainer}>
            <Editor
              editorState={editorState}
              editorClassName="demo-editor"
              wrapperClassName="wrapperClassName"
              onEditorStateChange={onEditorStateChange}
              placeholder="Detalles de la razon por la que abre la incidencia."
            />
          </aside>
          <UploadFile
            files={files}
            archiveHandler={archiveHandler}
            maxMB={1000000}
            messageErrorFile={
              'Solo se aceptan archivos con extensión ".png, .jpg, .pdf, .jpeg"'
            }
            messageErrorSize={
              'El archivo no debe de pesar más de 1MB'
            }
            acceptFile=".jpg, .png, .jpeg, .pdf"
          />
        </aside>
        {!load ? (
          <aside
            className={
              !dataUser.data.usuario
                ? classes.btnContainerNoUser
                : classes.btnContainer
            }
          >
            <Button
              onClick={createTicketHandler}
              className={classes.createTicketBtn}
              disabled={
                dataUser.data.usuario
                  ? !(subject && ticket && topic > 0)
                  : !(
                      subject &&
                      ticket &&
                      email &&
                      userName &&
                      topic > 0
                    )
              }
            >
              <DoneIcon className={classes.icon} />
              Crear Ticket
            </Button>
          </aside>
        ) : (
          <Loading />
        )}
      </div>
      {openMessage && (
        <Message
          openDialog={openMessage}
          closeDialog={handleCloseMessage}
          message={
            !dataUser.data.usuario
              ? `El ticket fue creado correctamente. El número del ticket es ${iDTicket}, ve a la sección de estado de ticket para ver el avance de este.`
              : `El ticket fue creado correctamente. El número del ticket es ${iDTicket}, puedes ir a la sección de base de conocimientos para ver el estado de este.`
          }
          btnText="De acuerdo"
        />
      )}
    </div>
  );
};

export default NewTicket;
