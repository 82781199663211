import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import MenuItem from '@mui/material/MenuItem';

import { noUserMenu } from 'constants/NoUserMenu';

const NoUserOptions = props => {
  const { t } = useTranslation(['header']);
  const closeMenuHandler = () => {
    props.closeMenu();
  };

  return (
    <div>
      {noUserMenu.map(item => (
        <MenuItem
          key={item.link}
          component={Link}
          to={item.link}
          onClick={closeMenuHandler}
        >
          {t(item.text)}
        </MenuItem>
      ))}
    </div>
  );
};

NoUserOptions.propTypes = {
  // eslint-disable-next-line react/require-default-props
  closeMenu: PropTypes.function
};

export default NoUserOptions;
