import { forwardRef } from 'react';
import PropTypes from 'prop-types';

import Button from '@mui/material/Button';
import DialogContent from '@mui/material/DialogContent';
import Dialog from '@mui/material/Dialog';
import Slide from '@mui/material/Slide';

import classes from './Message.module.css';

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const Message = props => {
  const handleClose = () => {
    props.closeDialog();
  };

  return (
    <Dialog
      open={props.openDialog}
      TransitionComponent={Transition}
      onClose={handleClose}
      width="md"
      fullWidth="true"
    >
      <div className={classes.titleSection}>
        <p>{props.title}</p>
      </div>
      <DialogContent>
        <p className={classes.subTitle}>{props.message}</p>
      </DialogContent>
      <div className={classes.btnsContainer}>
        <Button onClick={handleClose} className={classes.agreeBtn}>
          {props.btnText}
        </Button>
      </div>
    </Dialog>
  );
};

Message.propTypes = {
  // eslint-disable-next-line react/require-default-props
  title: PropTypes.string,
  // eslint-disable-next-line react/require-default-props
  message: PropTypes.string,
  // eslint-disable-next-line react/require-default-props
  openDialog: PropTypes.bool,
  // eslint-disable-next-line react/require-default-props
  closeDialog: PropTypes.func,
  // eslint-disable-next-line react/require-default-props
  btnText: PropTypes.string
};

export default Message;
