import PropTypes from 'prop-types';

import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

// import classes from './ErrorMessage.module.css';

const ErrorMessage = props => {
  return (
    <Snackbar open={props.open} autoHideDuration={6000}>
      <MuiAlert severity="error">{props.message}</MuiAlert>
    </Snackbar>
  );
};

ErrorMessage.propTypes = {
  // eslint-disable-next-line react/require-default-props
  open: PropTypes.boolen,
  // eslint-disable-next-line react/require-default-props
  message: PropTypes.string
};

export default ErrorMessage;
