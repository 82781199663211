import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  Route,
  BrowserRouter as Router,
  Routes
} from 'react-router-dom';

import { getUserInSession } from 'store/user-actions';

import Layout from 'components/layout/Layout';
import Home from 'Pages/home/Home';
import NewTicket from 'Pages/newTicket/NewTicket';
import Login from 'Pages/login/Login';
import CheckTicket from 'Pages/checkTicket/CheckTicket';

function App() {
  const [showBanner, setShowBanner] = useState(true);
  console.log('🚀 ~ App ~ showBanner:', showBanner);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getUserInSession(0));
  }, [dispatch]);

  const showBannerHandler = () => {
    setShowBanner(false);
  };

  return (
    <Router>
      <Layout showBanner={showBanner}>
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route exact path="/newTicket" element={<NewTicket />} />
          <Route
            exact
            path="/ticketState"
            element={<CheckTicket />}
          />
          <Route
            exact
            path="/login"
            element={<Login showBanner={showBannerHandler} />}
          />
        </Routes>
      </Layout>
    </Router>
  );
}

export default App;
