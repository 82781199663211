import classes from './Footer.module.css';

// const url = window.location.pathname.split('/');

const Footer = () => {
  const date = new Date();
  return (
    <footer className={classes.footerContainer}>
      <p>
        Copyright © {date.getFullYear()} Soporte Mayahii -Todos los
        derechos reservados.
      </p>
    </footer>
  );
};

export default Footer;
