import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import Button from '@mui/material/Button';

import classes from './FormContainer.module.css';

const FormContainer = props => {
  return (
    <section
      className={
        props.login
          ? classes.formSection
          : classes.formSectionRegister
      }
    >
      {props.login ? (
        <div className={classes.formContainerLogin}>
          <p className={classes.title}>{props.title}</p>
          {props.children}
        </div>
      ) : (
        <div className={classes.formContainerRegister}>
          <p className={classes.title}>{props.title}</p>
          <p className={classes.subtitle}>{props.subtitle}</p>
          {props.btnLink && (
            <Button
              component={Link}
              to={`/${props.btnLink}`}
              className={classes.sendOtherOptionBtn}
            >
              {props.btnText}
            </Button>
          )}
          {props.children}
        </div>
      )}
    </section>
  );
};

FormContainer.propTypes = {
  // eslint-disable-next-line react/require-default-props
  title: PropTypes.string,
  // eslint-disable-next-line react/require-default-props
  subtitle: PropTypes.string,
  // eslint-disable-next-line react/require-default-props
  btnLink: PropTypes.string,
  // eslint-disable-next-line react/require-default-props
  btnText: PropTypes.string,
  // eslint-disable-next-line react/require-default-props
  children: PropTypes.component,
  // eslint-disable-next-line react/require-default-props
  login: PropTypes.bool
};

export default FormContainer;
