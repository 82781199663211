import axios from 'axios';
import Cookies from 'js-cookie';
import { getAuthHeader } from './userInfo-actions';

const jwtCookie = Cookies.get(
  `${process.env.REACT_APP_API_COOKIE_CONSTANT}`
);

export const createTicket = async (
  subject,
  message,
  attachments,
  topicId,
  name,
  email
) => {
  let ticket = await axios.post(
    `${process.env.REACT_APP_API_TICKET}/post`,
    {
      subject,
      message: `data:text/html,${message}`,
      attachments,
      topicId,
      name,
      email
    },
    {
      headers: getAuthHeader(jwtCookie)
    }
  );
  if (ticket.data) {
    return ticket.data;
  }
  return false;
};

export const userTicketsList = async () => {
  const list = await axios.get(
    `${process.env.REACT_APP_API_TICKET}/list`,
    {
      headers: getAuthHeader(jwtCookie)
    }
  );
  if (list.data.length > 0) {
    return list.data;
  }
  return false;
};

export const checkTicket = async (number, email) => {
  const urlTicket = await axios.get(
    `${process.env.REACT_APP_API_TICKET}/get`,
    {
      params: { number, email }
    }
  );
  if (urlTicket.data) {
    window.open(urlTicket.data, '_blank');
  }
  return false;
};
