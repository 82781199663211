export const Topics = [
  {
    text: 'Consulta general',
    value: 12
  },
  {
    text: 'Cursos Arquidiócesis Monterrey',
    value: 13
  },
  {
    text: 'Formación Continua Docentes',
    value: 17
  },
  {
    text: 'Universidad de las Ciencias',
    value: 16
  },
  {
    text: 'UVM Licenciatura',
    value: 14
  },
  {
    text: 'UVM Preparatoria',
    value: 15
  },
  {
    text: 'UVB Licenciatura',
    value: 18
  }
];
