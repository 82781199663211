import i18n from 'i18next';
// import { useSelector } from 'react-redux';
// eslint-disable-next-line import/named
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';

//TODO cambiar cuando se mande a prod

const url = 'https://d2iq3j62em9oye.cloudfront.net/app/10';

const backendOptions = {
  loadPath: `${url}/locales/{{lng}}/{{ns}}.json`
};

i18n.use(initReactI18next).use(Backend).use(LanguageDetector).init({
  fallbackLng: 'es',
  backend: backendOptions
});

//TODO cambiar cuando se quiere probar localmente

// i18n.use(initReactI18next).use(Backend).use(LanguageDetector).init({
//   fallbackLng: 'es'
// });

// import i18n from 'i18next';
// import { initReactI18next } from 'react-i18next';
// import HttpApi from 'i18next-http-backend';
// import LanguageDetector from 'i18next-browser-languagedetector';

// const fallbackLng = ['en'];
// const availableLanguages = ['en', 'no'];

// i18n
//   .use(HttpApi) // load translations using http (default public/locals/en/translations)
//   .use(LanguageDetector) // detect user language
//   .use(initReactI18next) // pass the i18n instance to react-i18next.
//   .init({
//     fallbackLng, // fallback language is english.
//     preload: ['en', 'no'],
//     detection: {
//       checkWhitelist: true // options for language detection
//     },
//     supportedLngs: availableLanguages,
//     react: {
//       useSuspense: false
//     },
//     debug: false,
//     whitelist: availableLanguages
//   });

export default i18n;
