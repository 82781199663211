import { useState } from 'react';
import { useSelector } from 'react-redux';

import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';

import NoUserOptions from './noUserOptions/NoUserOptions';
import UserOptions from './userOptions/UserOptions';

import classes from './OptionsContainer.module.css';

const OptionsContainer = () => {
  const userInfo = useSelector(state => state.userInfo);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton
        id="user-button"
        aria-controls={open ? 'user-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
      >
        {userInfo.data.usuario ? (
          <figure className={classes.userIcon}>
            <img
              className={classes.img}
              src={
                userInfo.data.foto
                  ? userInfo.data.foto
                  : 'https://d1g6c01s9ssd7h.cloudfront.net/img/usuario12.png'
              }
              alt="icon"
            />
          </figure>
        ) : (
          <AccountCircleOutlinedIcon
            className={classes.noUserIcon}
          />
        )}
      </IconButton>
      {open && (
        <Menu
          id="user-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            'aria-labelledby': 'user-button'
          }}
        >
          {userInfo.data.usuario ? (
            <UserOptions closeMenu={handleClose} />
          ) : (
            <NoUserOptions closeMenu={handleClose} />
          )}
        </Menu>
      )}
    </>
  );
};

export default OptionsContainer;
