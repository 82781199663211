import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
// import { useTranslation } from 'react-i18next';

import Dropzone from 'react-dropzone';
import Button from '@mui/material/Button';

import classes from './UploadFile.module.css';

const UploadFile = props => {
  const [files, setFiles] = useState(props.files.length === 0 && []);
  const [filesWeight, setFilesWeight] = useState(0);

  useEffect(() => {
    if (props.files.length === 0) {
      setFiles([]);
      setFilesWeight(0);
    }
  }, [props.files]);

  const onDrop = filesOnDrop => {
    let fileWe = filesWeight;
    filesOnDrop.map(file => {
      if (file.size > 1000000) {
        console.log('wf');
      } else {
        fileWe = fileWe + file.size;
        setFilesWeight(fileWe);
        setFilesDataHandler(file.name);
        const fileData = file;
        const reader = new FileReader();
        reader.onload = event => {
          props.archiveHandler(
            event.target.result,
            file.type.replace('/', '.')
          );
        };
        reader.readAsDataURL(fileData);
      }
    });
  };

  const setFilesDataHandler = name => {
    const fileN = files;
    fileN.push(name);
    setFiles([...fileN]);
  };

  return (
    <>
      <div className={classes.dropzoneContainer}>
        <Dropzone
          className={classes.dropzone}
          onDrop={onDrop}
          accept={props.acceptFile}
          disabled={filesWeight >= 1000000}
        >
          <div className={classes.containerButtons}>
            {filesWeight >= 1000000 ? (
              <p className={classes.messageErrorDz}>
                No se pueden cargar más archivos, ya que llegaste al
                limite de 1MB
              </p>
            ) : (
              <p className={classes.messageDz}>
                Solo se puede cargar hasta 1MB en archivos.
              </p>
            )}
            {files.length > 0 ? (
              files.map((file, index) => (
                <p key={index} className={classes.fileName}>
                  {file}
                </p>
              ))
            ) : (
              <Button
                variant="contained"
                className={classes.buttonSave}
              >
                Adjuntar archivos
              </Button>
            )}
          </div>
        </Dropzone>
      </div>
    </>
  );
};

UploadFile.propTypes = {
  // eslint-disable-next-line react/require-default-props
  uploadButtonEnabled: PropTypes.func,
  // eslint-disable-next-line react/require-default-props
  archiveHandler: PropTypes.func,
  // eslint-disable-next-line react/require-default-props
  messageErrorFile: PropTypes.string,
  // eslint-disable-next-line react/require-default-props
  messageErrorSize: PropTypes.string,
  // eslint-disable-next-line react/require-default-props
  maxMB: PropTypes.number,
  // eslint-disable-next-line react/require-default-props
  acceptFile: PropTypes.string,
  // eslint-disable-next-line react/require-default-props
  files: PropTypes.array
};

export default UploadFile;
