import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import Button from '@mui/material/Button';
import HelpOutlined from '@mui/icons-material/HelpOutlined';
import SendOutlined from '@mui/icons-material/SendOutlined';
import SchoolOutlined from '@mui/icons-material/SchoolOutlined';

import classes from './Sections.module.css';

const Sections = () => {
  const [urlSection, setUrlSection] = useState('base');
  const dataUser = useSelector(state => state.userInfo);
  const url = window.location.pathname.split('/');

  useEffect(() => {
    if (url[1]) {
      setUrlSection(url[1]);
    }
  }, []);

  const changeSectionHandler = section => () => {
    setUrlSection(section);
  };

  return (
    <div className={classes.navConatiner}>
      <div
        className={
          dataUser.data.usuario
            ? classes.sectionsConatinerUser
            : classes.sectionsConatiner
        }
      >
        <Button
          onClick={changeSectionHandler('base')}
          component={Link}
          to="/"
          className={
            dataUser.data.usuario
              ? urlSection === 'base'
                ? classes.sectionUserSelected
                : classes.sectionUser
              : urlSection === 'base'
              ? classes.sectionSelected
              : classes.section
          }
        >
          <SchoolOutlined className={classes.iconSection} />
          <span className={classes.sectionName}>
            Base de conocimientos
          </span>
        </Button>
        <Button
          onClick={changeSectionHandler('newTicket')}
          component={Link}
          to="/newTicket"
          className={
            dataUser.data.usuario
              ? urlSection === 'newTicket'
                ? classes.sectionUserSelected
                : classes.sectionUser
              : urlSection === 'newTicket'
              ? classes.sectionSelected
              : classes.section
          }
        >
          <SendOutlined className={classes.iconSection} />
          <span className={classes.sectionName}>Nuevo ticket</span>
        </Button>
        {!dataUser.data.usuario && (
          <Button
            onClick={changeSectionHandler('ticketState')}
            component={Link}
            to="/ticketState"
            className={
              dataUser.data.usuario
                ? urlSection === 'ticketState'
                  ? classes.sectionUserSelected
                  : classes.sectionUser
                : urlSection === 'ticketState'
                ? classes.sectionSelected
                : classes.section
            }
          >
            <HelpOutlined className={classes.iconSection} />
            <span className={classes.sectionName}>
              Estado de ticket
            </span>
          </Button>
        )}
      </div>
    </div>
  );
};

export default Sections;
