import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import Header from 'uI/header/Header';
import Footer from 'uI/footer/Footer';
import Sections from 'uI/sections/Sections';

import classes from './Layout.module.css';

const Layout = props => {
  const dataUser = useSelector(state => state.userInfo);
  const url = window.location.pathname.split('/');

  return (
    dataUser.data.systemDate && (
      <main className={classes.mainContainer}>
        <Header />
        {url[1] !== 'login' && <Sections />}
        <section className={classes.sectionContainer}>
          <div className={classes.pageContainer}>
            {props.children}
          </div>
        </section>
        <Footer />
      </main>
    )
  );
};

Layout.propTypes = {
  // eslint-disable-next-line react/require-default-props
  children: PropTypes.data,
  // eslint-disable-next-line react/require-default-props
  showBanner: PropTypes.bool
};

export default Layout;
