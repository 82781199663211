export const Faq = [
  {
    title: 'Materias Mayahii',
    questions: [
      {
        question: '¿Qué información necesitan para atenderme?',
        answer:
          'Para que podamos apoyarte de manera rápida y eficiente necesitamos que en tu ticket indiques:',
        list: [
          '¿A qué plataforma, curso y/o academia te refieres?',
          '¿A qué institución/organización perteneces?',
          'Explica tu situación de manera detallada, indicando el mensaje de error que recibes.',
          'Si puedes adjuntar una captura de pantalla será de gran ayuda.'
        ]
      },
      {
        question:
          'No recibí mi correo de activación de la biblioteca.',
        answer:
          'Ya no enviamos correo de activación de la biblioteca.',
        list: [
          'Ingresa a Campus Mayahii con tus credenciales.',
          'Haz clic en Mi campus, barra lateral.',
          'Clic en la pestaña Mis libros.',
          'Clic en la portada del libro que deseas consultar. La biblioteca se abrirá sin necesidad de volver a hacer inicio de sesión.'
        ]
      },
      {
        question: 'Mi tarjeta está rechazada',
        answer:
          'La plataforma bancaria es independiente de nosotros, no podemos cambiar su decisión. Las soluciones más sencillas para que el pago sea aceptado son:',
        list: [
          'Recuerda que cualquier pago en internet, al igual que cualquier ingreso de información sensible, debe realizarse de preferencia desde una ventana de incógnito o ventana privada. Para abrirla haz clic en el menú Archivo de tu navegador y selecciona Nueva ventana de incógnito o Nueva ventana privada.',
          'Deja pasar al menos media hora antes de volver a intentar. Las plataformas bancarias consideran varios intentos fallidos repetidos como un posible comportamiento fraudulento.'
        ],
        extra:
          'Si el problema persiste utiliza uno de los dos otros métodos de pago disponibles.'
      },
      {
        question: 'Olvidé mi contraseña',
        answer:
          'Si olvidaste tu contraseña, no levantes un ticket. En la página de inicio de sesión tanto de la academia como de la biblioteca, está presente la opción Olvidé mi contraseña. Haz clic en ella y sigue las instrucciones para recuperar tu contraseña.'
      },
      {
        question: 'Escribí mal mi correo electrónico al registrarme',
        answer: '',
        list: [
          'Ingresa a Campus mayahi con la credenciales que usaste para crear tu cuenta y/o comprar el libro. (Es decir con el correcto incorrecto)',
          'Haz clic en el icono Mi cuenta abajo a la izquierda.',
          'En la ventana que se abre, corrige tu correo por uno personal existente, correcto y que no esté registrado en plataforma.',
          'Guarda la información.'
        ],
        extra:
          'OJO: No se puede cambiar el correo por uno que ya haya registrado en la plataforma. Si se intenta se generará un conflicto y los libros desaparecerán. En este caso ya no podremos ayudar.'
      },
      {
        question: 'El nombre en mi diploma es incorrecto',
        answer:
          'El nombre que aparece en tu constancia es el que registraste. Al realizar el registro, se mostró cómo iba a aparecer tu diploma. Una vez generado el diploma, no realizamos cambios. Para que tu nombre aparezca correctamente en los siguientes diplomas:',
        list: [
          'Inicia sesión en Campus Mayahii.',
          'Clic en el icono Mi Cuenta abajo a la izquierda.',
          'Se abre la pestaña Mi perfil.',
          'Edita tu nombre y verifica cómo aparecerá en el diploma.',
          'Guarda los cambios.'
        ]
      },
      {
        question: 'No recibí mi diploma',
        answer: '',
        list: [
          'Inicia sesión en Campus Mayahii.',
          'Ingresa al curso que corresponde al diploma esperado.',
          'Verifica que todas las palomas están en verde.',
          'En la barra lateral de Campus Mayahii haz clic en Mi portafolio.'
        ],
        extra: 'Aquí encuentras todos los diplomas que obtuviste. '
      },
      {
        question: 'Me equivoqué de libro, ¿pueden cambiarlo?',
        answer:
          'Es fundamental recordar que cada libro viene con una descripción que indica la modalidad, el periodo y la materia a los que corresponde. Esta información, con el nombre del usuario y su correo, se presenta 3 veces para su verificación antes de realizar la compra. Al realizar la compra, el usuario valida que la información es correcta. No realizamos cambios de manuales tomando en cuenta que están ligados a las academia. Lo que procede es el reembolso para que puedas comprar el libro correcto. Para poder realizar el reembolso, necesitamos asegurarnos que eres quien realizó la compra, por lo que compartir esta información:',
        list: [
          'Nombre completo registrado',
          'Correo de compra',
          'Manual adquirido',
          'Método de pago (SPI, Oxxo, tarjeta), comprobante',
          'Fecha y hora del pago.',
          'Comprobante de pago del libro correcto',
          'Motivo de la compra equivocada'
        ],
        extra:
          'Todo reembolso debe solicitarse en los 3 meses que siguen la compra. El tiempo para verlo reflejado en su cuenta depende del método de pago y del banco, entre unos días a mes y medio.'
      }
    ]
  },
  {
    title: 'Plataforma/accesibilidad',
    questions: [
      {
        question:
          '¿A dónde me dirijo si aún tengo dudas sobre la accesibilidad de la plataforma, o para hacerles llegar algún comentario o sugerencia ?',
        answer:
          'Abre un nuevo ticket aquí para que podamos atenderte. '
      },
      {
        question: '¿Cómo puedo agregar notas a cada lección?',
        answer:
          'Ingresa a la lección que deseas revisar. En la parte superior derecha encontrarás una serie de iconos de color gris: da clic en el de “Notas” (lápiz) y te abrirá un cuadro de texto para que ingreses tus apuntes con respecto a la lección. La ventaja de este recurso es que las notas se registran en el tiempo exacto del video, así puedes sincronizar tus dudas o tus comentarios con el video de la lección. Puedes borrar cada una de tus notas con el icono de papelera que está del lado derecho de la nota.'
      },
      {
        question:
          '¿Cómo puedo ver mi progreso y saber que terminé un curso?',
        answer:
          'Podrás verificar tu progreso en dichas actividades  de la siguiente manera:',
        list: [
          'Si el ícono está en color gris significa que no ha sido completada la actividad',
          'Si el ícono está en color naranja significa que la actividad está comenzada pero no completada todavía (esto en el caso de los videos o las coevaluaciones) o bien, que la evaluación se tomó pero no ha sido aprobada;',
          'Si el ícono está en color verde significa que la actividad fue completada satisfactoriamente.'
        ],
        extra:
          'Para saber que has concluido exitosamente un curso, todos las actividades representadas por estos iconos deberán estar marcadas en color verde.'
      },
      {
        question:
          '¿Dónde encuentro el foro de discusión y cómo participo en él?',
        answer:
          'Ingresa a cualquiera de las lecciones de un curso. Debajo del video de la lección encontrarás un espacio de comentarios estilo foro de discusión. Dentro del cuadro de texto ingresa un comentario de no más de 300 caracteres y da clic en el botón “Comentar”. Puedes retroalimentar también los comentarios de los demás participantes dando clic en los botones “Responder”: te abrirá otro cuadro de texto que te permitirá insertar tu comentario. El foro de discusión está diseñado para el intercambio de información entre usuarios, lo que les permite enriquecer su aprendizaje.'
      },
      {
        question: '¿Dónde puedo ver los cursos que estoy tomando?',
        answer:
          'Con tu cuenta de Mayahii, tienes acceso a una sección personalizada titulada Mi campus, que aparece al dar clic en la barra de menú izquierda (siempre que hayas iniciado sesión). Da clic en Mi Campus y aparecerá la pestaña Mis academias donde se encuentran todos los cursos. Una academia puede integrar uno o varios cursos. Una vez iniciado un curso en una academia. aparecerá también en la pestaña Mis cursos.'
      },
      {
        question: '¿Puedo acceder a un mismo curso más de una vez?',
        answer:
          'Sí, la plataforma te permite visitarlos y completar las actividades cuantas veces lo necesites, iniciando sesión desde cualquier dispositivo público o privado y cualquier momento. Es importante notar que algunos cursos tienen caducidad. Podrás acceder cuántas veces quieras dentro del limite de tiempo otorgado. La fecha de caducidad es fija, y el curso se cerrará automáticamente, aun si empezaste el curso. Es importante terminarlo antes de esta fecha.'
      }
    ]
  },
  {
    title: 'Formación continua Mayahii',
    questions: [
      {
        question: '¿Cómo ingreso a los cursos?',
        answer:
          'Para ingresar a ellos, asegúrate primero de iniciar sesión en campus.mayahii.com. En el menú principal se encuentra la sección de “Cursos”. Da clic en ella para visualizar en una pantalla completa la oferta actual. También puedes ingresar palabras clave en el recuadro de búsqueda para que se te muestren resultados en coincidencia. Da clic en el curso de tu elección para ingresar a él.'
      },
      {
        question:
          '¿Emitirán constancia que acredite la participación en el curso?',
        answer:
          'Al concluir exitosamente un curso, se emite una constancia personalizada y se envía automáticamente al correo electrónico del usuario, también se puede descargar desde Mis Diplomas del menú de la izquierda, o en la portada del curso que se ha concluido donde dice: Consulta tu diploma aquí.'
      },
      {
        question: '¿Qué pasa si he olvidado mi contraseña?',
        answer:
          'No te preocupes, puedes recuperar tu contraseña con un par de clics. Intenta iniciar sesión con el correo con el que te diste de alta y después da clic en “Olvidé mi contraseña”. Ingresa tu nueva contraseña como se te pide y recibirás un correo electrónico con instrucciones para ingresar. Síguelas al pie de la letra (¡No olvides guardar en un lugar seguro pero accesible para ti tu nueva contraseña!)'
      },
      {
        question:
          '¿Qué requisitos técnicos necesito para acceder a mis cursos?',
        answer:
          'La Plataforma Mayahii funciona correctamente en todos los dispositivos móviles con conexión a Internet y con cualquier navegador actualizado; en el caso de Explorer a partir de IE9+. Si tienes problemas de acceso, una recomendación es eliminar la caché del navegador o, si estás accediendo al curso desde tu institución o centro de trabajo, verifica que el administrador de TI permite la visualización de vídeos desde ese acceso a Internet.'
      }
    ]
  },
  {
    title: 'Cursos Arquidiócesis',
    questions: [
      {
        question:
          'Tengo dudas sobre los contenidos del curso y/o validez de la constancia en mi parroquia.',
        answer:
          'Por favor, envíe un correo electrónico con sus inquietudes a: cursos@iglesiademonterrey.com'
      },
      {
        question: '¿A nombre de quién se emiten las constancias?',
        answer:
          'Las constancias son unipersonales e intransferibles, y se emiten con el nombre de cada usuario tal cual como se ingresa al momento de registrarse en la plataforma.'
      },
      {
        question: '¿Cómo accedo a la constancia del curso?',
        answer:
          'Al concluir exitosamente un curso, se emite una constancia personalizada y se envía automáticamente al correo electrónico del usuario, también se puede descargar desde Mis Diplomas del menú de la izquierda, o en la portada del curso donde dice: Consulta tu diploma aquí.'
      },
      {
        question: '¿Cómo accedo al curso que he adquirido?',
        answer:
          'Unas vez iniciada su sesión en mayahii.com, diríjase a Mi Campus, del menú de la izquierda, y haga clic en Mis Cursos Mayahíi, del menú horizontal, desde ahí podrá acceder al curso que ha adquirido.'
      },
      {
        question: '¿Cómo canjeo un código?',
        answer:
          'Al hacer clic en Continuar con la compra de un curso, te apareceran los datos de compra. Al hacer clic en TENGO UN CÓDIGO, se abrirá un cuadro de texto en que podrás ingresar el código. Al hacer clic en Aplicar, se verá reflejado el descuento en el monto a pagar.'
      }
    ]
  }
];
