export const loginForm = [
  {
    text: 'email',
    id: 'userText',
    type: 'text'
  },
  {
    text: 'password',
    id: 'passwordText',
    type: 'password'
  }
];
