import axios from 'axios';
import Cookies from 'js-cookie';
import {
  getAuthHeader,
  removeCookieSession,
  setCookie
} from './userInfo-actions';
import { userDataActions } from './user-slice';

const jwtCookie = Cookies.get(
  `${process.env.REACT_APP_API_COOKIE_CONSTANT}`
);

export const getUserInSession = () => {
  return async dispatch => {
    let userData = await axios.get(
      `${process.env.REACT_APP_API_PATH_MAYAHII_USER_DATA}/account/homeload`,
      {
        params: {},
        headers: getAuthHeader(jwtCookie)
      }
    );
    if (userData.status === 200) {
      dispatch(
        userDataActions.info({
          userInfo: userData.data
        })
      );
    }
  };
};

export const getAcademies = searchString => {
  return async dispatch => {
    let academiesData = await axios.post(
      `${process.env.REACT_APP_API_PATH_ACADEMY}/user/academies`,
      {
        searchString
      },
      { headers: getAuthHeader(jwtCookie) }
    );
    if (academiesData.data) {
      dispatch(
        userDataActions.academies({
          academies: academiesData.data
        })
      );
    }
  };
};

export const getValidationName = course => {
  return async dispatch => {
    let validationName = await axios.get(
      `${process.env.REACT_APP_API_PATH_USER}/user/name/valid`,
      {
        params: {
          course
        },
        headers: getAuthHeader(jwtCookie)
      }
    );
    if (validationName.status === 200) {
      dispatch(
        userDataActions.validation({
          validationName:
            validationName.data.validated === 0 ? false : true
        })
      );
    }
  };
};

export const confirmNameValidate = async course => {
  let confirmNameData = await axios.put(
    `${process.env.REACT_APP_API_PATH_USER}/user/name/valid`,
    {
      course
    },
    { headers: getAuthHeader(jwtCookie) }
  );
  if (confirmNameData.data.updated) {
    return true;
  }
  return false;
};

export const logOut = () => {
  removeCookieSession();
};

export const getUserAccount = async email => {
  const userAccount = await axios.get(
    `${process.env.REACT_APP_API_PATH_MAYAHII_USER_INFO}/auth/user/check`,
    {
      params: {
        email
      }
    }
  );
  if (userAccount.data.userVO) {
    return true;
  }
  return false;
};

export const logIn = async (email, password) => {
  let logIn = await axios.get(
    `${process.env.REACT_APP_API_PATH_MAYAHII_USER_INFO}/auth`,
    {
      params: {
        email,
        password
      }
    }
  );
  if (!logIn.data.token) {
    return true;
  } else {
    setCookie(logIn.data.token);
    window.location.href = '/';
  }
};

export const registerUser = async (
  firstName,
  lastName,
  emailUser,
  passUser,
  lada,
  cellphone,
  alternativeEmail,
  languageCode,
  countryCode,
  currencyCode,
  currencySymbol,
  termsLogin,
  codigo,
  passConfirmUser,
  idAcademy,
  academy
) => {
  // const url = window.location.pathname.split('/');
  let register = await axios.get(
    `${process.env.REACT_APP_API_PATH_MAYAHII}/accounts/createAccountAction`,
    {
      params: {
        firstName,
        lastName,
        emailUser,
        passUser,
        lada,
        cellphone,
        alternativeEmail,
        countryCode,
        languageCode,
        currencyCode,
        currencySymbol,
        termsLogin,
        codigo,
        passConfirmUser,
        idAcademy,
        academy
      }
    },
    {
      withCredentials: true
    }
  );
  if (register.data.value === false) {
    return false;
  } else {
    // window.location.href = `/${url[1]}`;
    return true;
  }
};

export const updateLanguageUser = (userId, language) => {
  return async () => {
    await axios.put(
      `${process.env.REACT_APP_API_PATH_USER}/user/language`,
      {
        userId,
        language
      },
      { headers: getAuthHeader() }
    );
  };
};
