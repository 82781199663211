import { configureStore } from '@reduxjs/toolkit';

import userDataActions from './user-slice';

const store = configureStore({
  reducer: {
    userInfo: userDataActions.reducer
  }
});

export default store;
