import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import OptionsContainer from './optionsContainer/OptionsContainer';

import classes from './UserOptionsHeader.module.css';

const UserOptionsHeader = () => {
  const userInfo = useSelector(state => state.userInfo);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (userInfo.data.systemDate) {
      setIsLoading(false);
    }
  }, [userInfo]);

  return (
    <div className={classes.userOptionsContainer}>
      {isLoading && ''}
      {!isLoading && (
        <>
          <OptionsContainer />
        </>
      )}
    </div>
  );
};

export default UserOptionsHeader;
