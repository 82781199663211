import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { Faq } from 'constants/Faq';

import classes from './FaqContainer.module.css';

const FaqContainer = () => {
  return (
    <>
      <p className={classes.faqTitle}>Preguntas frecuentes</p>
      {Faq.map(faq => (
        <Accordion key={faq.title}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            className={classes.accordionCategoryContainer}
          >
            <p className={classes.accordionTitle}>{faq.title}</p>
          </AccordionSummary>
          <AccordionDetails
            className={classes.accordionCategoryContainer}
          >
            {faq.questions.map(question => (
              <Accordion key={question.question}>
                <AccordionSummary
                  className={classes.accordionQuestionContainer}
                  expandIcon={<ExpandMoreIcon />}
                >
                  <p className={classes.accordionTitle}>
                    {question.question}
                  </p>
                </AccordionSummary>
                <AccordionDetails
                  className={classes.accordionQuestionContainer}
                >
                  <p>{question.answer}</p>
                  {question.list && (
                    <ul>
                      {question.list.map(item => (
                        <li key={item}>{item}</li>
                      ))}
                    </ul>
                  )}
                  {question.extra && <p>{question.extra}</p>}
                </AccordionDetails>
              </Accordion>
            ))}
          </AccordionDetails>
        </Accordion>
      ))}
    </>
  );
};

export default FaqContainer;
