import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';

// import MovilMenu from '../MovilMenu/MovilMenu';
import UserOptionsHeader from 'uI/userOptionsHeader/UserOptionsHeader';

import classes from './Header.module.css';

// const url = window.location.pathname.split('/');

const Header = () => {
  return (
    <AppBar position="static" className={classes.headerContainer}>
      <Toolbar className={classes.header}>
        <figure className={classes.mayahiiImage}>
          <img
            src="https://d1g6c01s9ssd7h.cloudfront.net/img/logoHC.png"
            alt="Mayahii image"
            className={classes.img}
          />
        </figure>
        <UserOptionsHeader />
        {/* <MovilMenu />
        <UserOptionsHeader /> */}
      </Toolbar>
    </AppBar>
  );
};

export default Header;
