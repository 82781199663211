import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import MenuItem from '@mui/material/MenuItem';
import Divider from '@mui/material/Divider';

import { logOut } from 'store/user-actions';
import classes from './UserOptions.module.css';

const UserOptions = () => {
  const { t } = useTranslation(['header']);
  const dispatch = useDispatch();
  const userInfo = useSelector(state => state.userInfo);

  const logoutUser = () => {
    dispatch(logOut());
  };

  return (
    <div>
      <MenuItem className={classes.userInfo}>
        <figure className={classes.userAvatar}>
          <img
            className={classes.img}
            src={
              userInfo.data.foto
                ? userInfo.data.foto
                : 'https://d1g6c01s9ssd7h.cloudfront.net/img/usuario12.png'
            }
            alt="userIcon"
          />
        </figure>
        <p className={classes.userName}>{userInfo.data.nombre}</p>
        <p className={classes.userEmail}>{userInfo.data.email}</p>
      </MenuItem>
      <Divider />
      <MenuItem onClick={logoutUser}>{t('log_out')}</MenuItem>
    </div>
  );
};

UserOptions.propTypes = {
  // eslint-disable-next-line react/require-default-props
  closeMenu: PropTypes.function
};

export default UserOptions;
