import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import LoginForm from 'components/loginForm/LoginForm';
import FormContainer from 'uI/formContainer/FormContainer';

const Login = props => {
  const { t } = useTranslation(['login']);
  const dataUser = useSelector(state => state.userInfo);

  useEffect(() => {
    props.showBanner();
  }, []);

  useEffect(() => {
    if (dataUser.data.usuario) {
      window.location.href = '/';
    }
  }, [dataUser.data]);

  return (
    <FormContainer
      title={t('principal_text')}
      btnLink="register"
      login
    >
      <LoginForm />
    </FormContainer>
  );
};

Login.propTypes = {
  // eslint-disable-next-line react/require-default-props
  showBanner: PropTypes.func
};

export default Login;
