import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import Loading from 'uI/loading/Loading';
import FaqContainer from 'components/home/faqContainer/FaqContainer';
import UserTickets from 'components/home/userTickets/UserTickets';

import { userTicketsList } from 'store/ticket.actions';

import classes from './Home.module.css';

const Home = () => {
  const [loading, setLoading] = useState(true);
  const [tickets, setTickets] = useState('');

  const dataUser = useSelector(state => state.userInfo);

  useEffect(() => {
    if (dataUser.data.usuario) {
      (async () => {
        setTickets(await userTicketsList());
        setLoading(false);
      })();
    } else {
      setLoading(false);
    }
  }, [dataUser]);

  return (
    <div className={classes.homeContainer}>
      <h1 className={classes.title}>
        Bienvenido/a al Centro de ayuda
      </h1>
      <p className={classes.description}>
        Para poder darte un mejor soporte, utilizamos el Centro de
        ayuda Mayahii. Vamos a asignar un número único de ticket a
        cada una de tus solicitudes. Es importante que lo anotes, lo
        utilizarás para dar seguimiento de su progreso y ver las
        respuestas en línea. Para tu referencia archivamos cada uno
        de los ticket de ayuda. Por lo tanto es fundamental que{' '}
        <strong>
          no abras varios tickets sino uno solo al que le darás
          seguimiento
        </strong>
        . Es muy importante poner un correo válido, de preferencia el
        de tu cuenta de Campus Mayahii, para poder atenderte de forma
        correcta.
      </p>
      {!loading ? <UserTickets tickets={tickets} /> : <Loading />}
      <FaqContainer />
    </div>
  );
};

export default Home;
