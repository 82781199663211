import { useEffect, useState } from 'react';

import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';

import Loading from 'uI/loading/Loading';
import ErrorMessage from 'uI/errorMessage/ErrorMessage';

import { checkTicket } from 'store/ticket.actions';

import classes from './CheckTicket.module.css';

const CheckTicket = () => {
  const [load, setLoad] = useState(false);
  const [error, setError] = useState(false);
  const [email, setEmail] = useState('');
  const [ticket, setTicket] = useState('');

  useEffect(() => {
    setError(false);
  }, [email, ticket]);

  const handleChange = type => event => {
    if (type === 'email') {
      setEmail(event.target.value);
    } else {
      setTicket(event.target.value);
    }
  };

  const checkTicketHandler = () => {
    setLoad(true);
    (async () => {
      let createTicketData = await checkTicket(ticket, email);
      setLoad(false);
      if (!createTicketData) {
        setError(true);
      }
      console.log('createTicketData', createTicketData);
    })();
  };

  return (
    <div className={classes.checkTicketContainer}>
      <p className={classes.title}>Ver Estado de un Ticket</p>
      <p className={classes.text}>
        Por favor proporcione su dirección de e-mail y el número de
        Ticket. Este le registrará para ver su Ticket.
      </p>
      <div className={classes.inputsContainer}>
        <p>Direccción de Correo</p>
        <TextField
          value={email}
          className={classes.input}
          size="small"
          onChange={handleChange('email')}
        />
        <p>Número de Ticket</p>
        <TextField
          value={ticket}
          className={classes.input}
          size="small"
          onChange={handleChange('userName')}
        />
        {!load ? (
          <Button
            onClick={checkTicketHandler}
            className={classes.sendBtn}
            disabled={!(email && ticket)}
          >
            Ver Ticket
          </Button>
        ) : (
          <Loading />
        )}
      </div>
      <p className={classes.text}>
        Si es la primera vez que se pone en contacto con nosotros o
        no recuerda el número de Ticket, por favor abra un nuevo
        Ticket
      </p>
      {error && (
        <ErrorMessage
          open={error}
          message="Correo electrónico válido y el numero de ticket es requerido"
        />
      )}
    </div>
  );
};

export default CheckTicket;
