import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import store from './store/index';
import { Suspense } from 'react';

import './index.css';
import App from './App';

import './i18n';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Provider store={store}>
    <Suspense>
      <App />
    </Suspense>
  </Provider>
);
