import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Button from '@mui/material/Button';

import classes from './UserTickets.module.css';

const UserTickets = props => {
  // const [noOpenTickets, setNoOpenTickets] = useState(true);
  const [noCloseTickets, setNoCloseTickets] = useState(true);

  useEffect(() => {
    if (props.tickets.length > 0) {
      props.tickets.map(ticket => {
        // if (ticket.is_open === 1 && ticket.is_hidden === 0) {
        //   setNoOpenTickets(false);
        // }
        if (ticket.is_open === 0 && ticket.is_hidden === 0) {
          setNoCloseTickets(false);
        }
      });
    }
  }, [props.tickets]);

  return (
    <>
      {props.tickets.length > 0 && (
        <>
          <div className={classes.myTicketsContainer}>
            <p className={classes.myTicketsTitle}>Tus tickets</p>
            <ul className={classes.ticketsList}>
              {props.tickets.map(
                ticket =>
                  ticket.is_open === 1 &&
                  ticket.is_hidden === 0 && (
                    <li
                      key={ticket.id_ticket}
                      className={classes.ticket}
                    >
                      <Button
                        className={classes.ticketLink}
                        component="a"
                        href={`https://cshelp.mayahii.com/view.php?auth=${ticket.token}`}
                        target="_blank"
                      >
                        {ticket.title}
                      </Button>
                    </li>
                  )
              )}
            </ul>
            {/* <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="openTickets-content"
                id="openTickets-header"
              >
                Tickets abiertos
              </AccordionSummary>
              <AccordionDetails>
                <ul className={classes.ticketsList}>
                  {props.tickets.map(
                    ticket =>
                      ticket.is_open === 1 &&
                      ticket.is_hidden === 0 && (
                        <li
                          key={ticket.id_ticket}
                          className={classes.ticket}
                        >
                          <Button
                            className={classes.ticketLink}
                            component="a"
                            href={`https://cshelp.mayahii.com/view.php?auth=${ticket.token}`}
                            target="_blank"
                          >
                            {ticket.title}
                          </Button>
                        </li>
                      )
                  )}
                  {noOpenTickets && <p>No hay tickets abiertos</p>}
                </ul>
              </AccordionDetails>
            </Accordion> */}
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="closeTickets-content"
                id="closeTickets-header"
              >
                Tickets cerrados
              </AccordionSummary>
              <AccordionDetails>
                <ul className={classes.ticketsList}>
                  {props.tickets.map(
                    ticket =>
                      ticket.is_open === 0 &&
                      ticket.is_hidden === 0 && (
                        <li
                          key={ticket.id_ticket}
                          className={classes.ticket}
                        >
                          <Button
                            className={classes.ticketLink}
                            component="a"
                            href={`https://cshelp.mayahii.com/view.php?auth=${ticket.token}`}
                            target="_blank"
                          >
                            {ticket.title} {ticket.id_ticket}
                          </Button>
                        </li>
                      )
                  )}
                  {noCloseTickets && <p>No hay tickets cerrados</p>}
                </ul>
              </AccordionDetails>
            </Accordion>
          </div>
          {/* <ul>
            {props.tickets.map(ticket =>
              ticket.is_open === value ? (
                <li
                  key={ticket.id_ticket}
                  className={classes.ticket}
                >
                  <Button
                    className={classes.ticketLink}
                    component="a"
                    href={`https://cshelp.mayahii.com/view.php?auth=${ticket.token}`}
                    target="_blank"
                  >
                    {ticket.title}
                  </Button>
                </li>
              ) : (
                <p key={ticket.id_ticket}>No hay tickets</p>
              )
            )}
          </ul> */}
          {/* <ul>
            {props.tickets.map(ticket =>
              ticket.is_open === 1 ? (
                <li
                  key={ticket.id_ticket}
                  className={classes.ticket}
                >
                  <Button
                    className={classes.ticketLink}
                    component="a"
                    href={`https://cshelp.mayahii.com/view.php?auth=${ticket.token}`}
                    target="_blank"
                  >
                    {ticket.title}
                  </Button>
                </li>
              ) : (
                <p key={ticket.id_ticket}>No hay tickets</p>
              )
            )}
          </ul> */}
        </>
      )}
    </>
  );
};

UserTickets.propTypes = {
  // eslint-disable-next-line react/require-default-props
  tickets: PropTypes.array
};

export default UserTickets;
