import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';

import { useTranslation } from 'react-i18next';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import OutlinedInput from '@mui/material/OutlinedInput';
import Button from '@mui/material/Button';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Loading from 'uI/loading/Loading';

import { loginForm } from 'constants/LoginForm';
import { getUserAccount, logIn } from 'store/user-actions';

import ErrorMessage from 'uI/errorMessage/ErrorMessage';

import classes from './LoginForm.module.css';

const LoginForm = () => {
  const { t } = useTranslation(['login']);
  const [showPassword, setShowPassword] = useState(false);
  const [errorLogin, setErrorLogin] = useState(false);
  const { register, handleSubmit, watch } = useForm();
  const [existUser, setExistUser] = useState(false);
  const [sendData, setSendData] = useState(false);
  const [userText, passwordText] = watch([
    'userText',
    'passwordText'
  ]);

  useEffect(() => {
    setErrorLogin(false);
  }, [userText, passwordText]);

  useEffect(() => {
    setExistUser(false);
  }, [userText]);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const onSubmit = async data => {
    setSendData(true);
    const loginUser = await logIn(data.userText, data.passwordText);
    if (loginUser) {
      setSendData(false);
    }
    setErrorLogin(loginUser);
  };

  const checkUser = async () => {
    const checkUserData = await getUserAccount(userText);
    if (checkUserData) {
      setExistUser(true);
    } else {
      setExistUser('noUser');
    }
  };

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className={classes.loginForm}
    >
      {loginForm.map(input =>
        input.type === 'password' ? (
          <>
            {existUser === true && (
              <OutlinedInput
                key={input.id}
                className={classes.textField}
                {...register(`${input.id}`)}
                type={showPassword ? 'text' : 'password'}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      onClick={handleClickShowPassword}
                      edge="end"
                    >
                      {showPassword ? (
                        <VisibilityOff />
                      ) : (
                        <Visibility />
                      )}
                    </IconButton>
                  </InputAdornment>
                }
                placeholder={t(input.text)}
              />
            )}
          </>
        ) : (
          <>
            <OutlinedInput
              key={input.id}
              className={classes.textField}
              {...register(`${input.id}`)}
              placeholder={t(input.text)}
              onBlur={checkUser}
            />
            {existUser === true && (
              <p className={classes.coplementaryText}>
                {t('exist_account')}
              </p>
            )}
            {!existUser && (
              <Button
                onClick={checkUser}
                className={classes.checkUserBtn}
                variant="contained"
              >
                {t('continue')}
              </Button>
            )}
          </>
        )
      )}
      {existUser === true && (
        <div className={classes.btnContainer}>
          {!sendData ? (
            <Button className={classes.btnSend} type="submit">
              {t('continue')}
            </Button>
          ) : (
            <Loading />
          )}
        </div>
      )}
      {existUser === 'noUser' && (
        <p className={classes.coplementaryText}>
          {t('not_account')}
        </p>
      )}
      {errorLogin && (
        <ErrorMessage
          open={errorLogin}
          message={t('username_and_password_not_match')}
        />
      )}
    </form>
  );
};

export default LoginForm;
